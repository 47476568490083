<template>
  <div class="content" v-loading="loading">
    <el-form
      size="small"
      class="small-form"
      :model="detailForm"
      ref="detailForm"
      :rules="rules"
      label-width="85px"
    >
      <div class="confirm-msg" v-if="!detailForm.id">
        <p class="title" style="padding-top: 4px">发布角色</p>
        <div class="confirm-content">
          <el-row>
            <el-col :span="12">
              <el-radio v-model="memberType" :label="0">后台发布</el-radio>
            </el-col>
            <el-col :span="12">
              <el-radio v-model="memberType" :label="1">会员发布</el-radio>
            </el-col>
          </el-row>
          <el-row style="padding-bottom: 0">
            <el-col :span="12">
              <el-form-item
                label="联系方式："
                label-width="85px"
                prop="contact_type"
              >
                <el-radio-group v-model="detailForm.contact_type">
                  <el-radio :label="0">联系号码</el-radio>
                  <el-radio :label="1">邮箱地址</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                v-if="detailForm.contact_type === 0"
                style="margin-bottom: 0"
                label="联系号码："
                prop="phone"
              >
                <el-input
                  style="width: 150px"
                  type="number"
                  v-model.number="detailForm.phone"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item
                v-if="detailForm.contact_type === 1"
                style="margin-bottom: 0"
                label="邮箱地址："
                prop="email"
              >
                <el-input
                  style="width: 150px"
                  v-model="detailForm.email"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col style="height: 32px" :span="12" v-if="memberType"
              ><span class="label">选择会员：</span>
              <el-button
                size="small"
                type="primary"
                @click="openSelectMember = true"
              >
                {{ memberName ? memberName : '选择会员' }}
              </el-button>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="confirm-msg" v-else>
        <p class="title" style="padding-top: 4px">内容详情</p>
        <div class="confirm-content">
          <el-row style="padding-bottom: 0">
            <el-col :span="8"
              ><span class="label" style="width: 110px">发布人：</span
              >{{ detailForm.nickname }}</el-col
            >
            <el-col :span="8"
              ><span class="label" style="width: 110px">架构/会内职务：</span
              >{{ detailForm.title_name }}</el-col
            >
            <el-col :span="8">
              <el-form-item label-width="110px" label="审核状态：">
                <span
                  :class="[
                    'status-dot',
                    detailForm.check_text === '已通过'
                      ? 'green'
                      : detailForm.check_text === '未通过'
                      ? 'grey'
                      : 'yellow',
                  ]"
                ></span>
                {{ detailForm.check_text }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="padding-bottom: 0">
            <el-col :span="8"
              ><span class="label" style="width: 110px">置顶：</span>
              <el-checkbox v-model="isTop" @change="setTop"></el-checkbox>
              <el-input-number
                style="margin-left: 15px"
                size="small"
                v-if="isTop"
                v-model="detailForm.is_top"
                :min="1"
              ></el-input-number>
            </el-col>
            <el-col :span="8">
              <el-form-item
                style="margin-bottom: 0"
                label-width="110px"
                label="联系方式："
                prop="contact_type"
              >
                <el-radio-group v-model="detailForm.contact_type">
                  <el-radio :label="0">联系号码</el-radio>
                  <el-radio :label="1">邮箱地址</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="detailForm.contact_type === 0">
              <el-form-item label-width="110px" label="联系号码：" prop="phone">
                <el-input
                  style="width: 120px"
                  type="number"
                  v-model.number="detailForm.phone"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="detailForm.contact_type === 1">
              <el-form-item label-width="110px" label="邮箱地址：" prop="email">
                <el-input
                  style="width: 120px"
                  v-model="detailForm.email"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="内容ID：" label-width="110px">
                {{ detailForm.id }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="padding-bottom: 0">
            <el-col :span="8">
              <el-form-item label-width="110px" label="付费方式：">
                {{
                  !detailForm.is_pay
                    ? '免费'
                    : !detailForm.pay_type
                    ? detailForm.is_pay + '元'
                    : detailForm.is_pay + '积分'
                }}
              </el-form-item>
            </el-col>
            <el-col :span="16" v-if="pay">
              <el-form-item label-width="110px" label="付费人数：">
                {{ detailForm.cyc_circle_order_count + '人' }}&nbsp<el-button
                  type="text"
                  style="padding: 0"
                  @click="openCheckPayment = true"
                  >查看</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="confirm-msg">
        <p class="title">发布设置</p>
        <div class="confirm-content">
          <el-row>
            <el-col :span="24">
              <el-form-item label="付费方式：" style="margin-bottom: 0">
                <el-radio v-model="pay" :label="0">无需付费</el-radio>
                <el-radio v-model="pay" :label="1"
                  >需要付费
                  <el-input
                    :disabled="!pay"
                    style="width: 160px; margin: -10px 0 0 10px"
                    type="number"
                    placeholder="请输入数值"
                    v-model.number="detailForm.is_pay"
                  >
                    <template slot="prepend">
                      <el-select style="width: 85px" v-model="payType">
                        <el-option
                          v-for="item in payTypeOptions"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-input>
                </el-radio>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="padding-bottom: 0">
            <el-col :span="8">
              <el-form-item
                style="margin-bottom: 0"
                label="内容类型："
                prop="is_demand"
              >
                <el-select
                  v-model="detailForm.is_demand"
                  @change="demandSelected"
                >
                  <el-option
                    v-for="demand in isDemandOptions"
                    :label="demand.name"
                    :value="demand.id"
                    :key="demand.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                style="margin-bottom: 0"
                label="内容分类："
                prop="category_id"
              >
                <div class="flex">
                  <el-select v-model="detailForm.category_id">
                    <el-option
                      v-for="item in CategoryOptions"
                      :label="item.name"
                      :value="item.id"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                  <!-- 通用添加分类组件 -->
                  <AddCategoryButton
                    :style="{
                      marginLeft: '12px',
                    }"
                    :updateList="getCategoryList"
                    :getApi="getApi"
                    :form="{
                      name: '',
                      is_demand: 0,
                    }"
                  >
                    <template slot-scope="{ CategoryData }">
                      <el-form-item
                        label="所属分类："
                        label-width="95px"
                        style="margin-bottom: 12px"
                      >
                        <el-radio v-model="CategoryData.is_demand" :label="0"
                          >供应</el-radio
                        >
                        <el-radio v-model="CategoryData.is_demand" :label="1"
                          >需求</el-radio
                        >
                      </el-form-item>
                    </template>
                  </AddCategoryButton>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                style="margin-bottom: 0"
                label="阅读权限："
                prop="is_public"
              >
                <el-select v-model="detailForm.is_public">
                  <el-option
                    v-for="item in PublicOptions"
                    :label="item.name"
                    :value="item.id"
                    :key="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="confirm-msg">
        <p class="title">资源内容</p>
        <div class="confirm-content">
          <el-input
            style="height: auto; width: 100%; max-width: none"
            type="textarea"
            v-model="detailForm.content"
            :autosize="{ minRows: 6 }"
            placeholder="请输入"
          />
          <div style="padding: 10px 0">
            <el-radio v-model="detailForm.type" :label="0">图片</el-radio>
            <el-radio v-model="detailForm.type" :label="1">视频</el-radio>
          </div>
          <media-wall
            v-if="!detailForm.type"
            :UploadType="['image']"
            v-model="media.image"
            :width="135"
            :height="94"
            :limit="9 - media.image.length"
          ></media-wall>
          <media-wall
            v-else
            v-model="media.video"
            :width="135"
            :height="94"
            UploadType="video"
            :limit="9 - media.video.length"
            addText="上传视频"
          ></media-wall>
          <el-button
            v-if="detailForm.id"
            style="margin-top: 10px"
            type="primary"
            @click="openCommentList = true"
            >所有评论</el-button
          >
        </div>
      </div>
    </el-form>
    <div class="replay-list" v-if="detailForm.id">
      <p class="title">内容数据</p>
      <div class="confirm-content">
        <ContentList v-model="detailForm" />
      </div>
    </div>
    <div class="replay-list" v-if="detailForm.id && log_num">
      <p class="title">操作记录</p>
      <div class="confirm-content">
        <LogRecodeList :id="detailForm.id" :recodeNum.sync="log_num" />
      </div>
    </div>
    <CommentList v-model="openCommentList" :id="detailForm.id" />
    <SelectMember
      v-model="openSelectMember"
      :selectType="0"
      @changeSelect="handleSelect"
    />
    <CheckPayment v-model="openCheckPayment" :id="detailForm.id" />
    <FixedActionBar>
      <el-button
        type="primary"
        size="medium"
        @click="formSubmit('detailForm')"
        :loading="saveLoading"
        >保存
      </el-button>
      <el-button size="medium" @click="cancel">返回</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import {
  contentDetail,
  CategoryList,
  logRecodeList,
  contentSave,
} from '../../api/content-detail'
import { saveCategory } from '../../api/category-list'

import regs from '@/base/utils/regs'
import MediaWall from '@/modules/common/components/MediaWall.vue'
import ContentList from '../../components/CircleContent/ContentList.vue'
import LogRecodeList from '../../components/CircleContent/LogRecodeList.vue'
import CommentList from '../../components/CircleContent/CommentList.vue'
import SelectMember from '@/modules/common/components/MemberSelector'
import CheckPayment from '../../components/CircleContent/CheckPayment.vue'
import FixedActionBar from '@/base/layout/FixedActionBar'
import AddCategoryButton from '@/base/components/Base/AddCategoryButton.vue'

export default {
  data() {
    return {
      loading: false,
      saveLoading: false,
      detailForm: {
        id: 0,
        user_id: 0,
        phone: '',
        content: '',
        res: [],
        category_id: '',
        is_demand: 0,
        is_pay: 0,
        pay_type: 0,
        is_top: 0, //
        is_public: '', // 是否公开（0：否，1：是）
        type: 0,
        contact_type: 0, // 0：手机号， 1：邮箱
        email: '',
      },
      media: {
        image: [],
        video: [],
      },
      memberType: 0, // 发布人类型
      memberName: '',
      handleRecode: [], // 操作记录
      log_num: 1,
      isTop: 0,
      pay: 0,
      payType: 0,
      isDemandOptions: [
        { name: '供应', id: 0 },
        { name: '需求', id: 1 },
      ],
      CategoryOptions: [],
      // 付费方式
      payTypeOptions: [
        { name: '金额', id: 0 },
        { name: '积分', id: 1 },
      ],

      rules: {
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { validator: regs.phone, trigger: 'blur' },
        ],
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { validator: regs.email, trigger: 'blur' },
        ],
        is_demand: [
          { required: true, message: '请选择内容类型', trigger: 'change' },
        ],
        category_id: [
          { required: true, message: '请选择内容分类', trigger: 'change' },
        ],
        is_public: [
          { required: true, message: '请选择阅读权限', trigger: 'change' },
        ],
      },
      openCommentList: false,
      openSelectMember: false,
      openCheckPayment: false,
    }
  },
  computed: {
    // 阅读权限
    PublicOptions: {
      get() {
        if (this.detailForm.user_id || this.memberType) {
          return [
            { name: '对外公开(所有人可见)', id: 1 },
            { name: '对内公开(仅会内成员可见)', id: 0 },
            {
              name: '对同架构成员公开仅同架构成员可见）',
              id: 2,
            },
          ]
        } else {
          return [
            { name: '对外公开(所有人可见)', id: 1 },
            { name: '对内公开(仅会内成员可见)', id: 0 },
          ]
        }
      },
    },
  },
  methods: {
    // 添加分类通用
    getApi(data) {
      return saveCategory(data)
    },
    getCircleDetail() {
      this.loading = true
      contentDetail({ id: this.detailForm.id })
        .then((res) => {
          const { data } = res
          data.res = data.res || []
          this.detailForm = data
          if (data.type) {
            this.media.video = data.res
          } else {
            this.media.image = data.res
          }
          // user_id !== 0 表示不是管理员发布的,增加一个选项

          this.pay = data.is_pay ? 1 : 0
          this.isTop = !!data.is_top
          this.getCategoryList()
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 选择内容类型后
    demandSelected(value) {
      this.detailForm.category_id = ''
      this.getCategoryList()
    },
    // 获取分类列表
    getCategoryList() {
      CategoryList({ is_demand: this.detailForm.is_demand })
        .then((res) => {
          this.CategoryOptions = res.data
          console.log(this.CategoryOptions, 'CategoryOptions')
        })
        .catch((err) => {})
    },
    // 获取记录列表
    getLogRecodeList() {
      logRecodeList(this.requesData)
        .then((res) => {
          const { data } = res
          this.RecodeList = data.data
          this.pageData = data
        })
        .catch((err) => {})
    },
    // 电话号码验证
    phonePass(rule, value, callback) {
      if (regs.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确的手机号码'))
      }
    },
    // 选择会员操作
    handleSelect(e) {
      this.detailForm.user_id = e[0].user_id
      this.memberName = e[0].nickname
    },
    // 设置置顶
    setTop(value) {
      this.detailForm.is_top = value ? 1 : 0
    },
    // 保存
    formSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true
          const data = { ...this.detailForm }
          data.res = data.type
            ? JSON.stringify(this.media.video)
            : JSON.stringify(this.media.image)
          if (!data.content && data.res === '[]') {
            this.$message.error('请填写资源内容')
            this.saveLoading = false
            return
          }
          if (this.memberType && !data.user_id) {
            this.$message.error('请选择会员')
            this.saveLoading = false
            return
          }
          if (this.$route.params.type === 'add') {
            data.user_id = this.memberType ? data.user_id : 0
          }
          contentSave(data)
            .then((res) => {
              this.$message.success(res.msg)
              this.saveLoading = false
              if (this.$route.params.type === 'view') {
                this.$router.go(-1)
              } else {
                this.$router.push({ name: 'circleContent' })
              }
            })
            .catch((err) => {
              this.saveLoading = false
            })
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName('is-error')
            isError[0].scrollIntoView({ block: 'center' })
          }, 100)
          this.$message.error('请填写完整信息')
          return false
        }
      })
    },
    //返回
    cancel() {
      if (this.$route.params.type === 'view') {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'circleContent' })
      }
    },
  },
  watch: {
    memberType: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return
        // 切换发布人要重新选择权限
        this.detailForm.is_public = ''
      },
    },
  },
  created() {
    this.detailForm.id = this.$route.params.id == 0 ? 0 : this.$route.params.id
    if (this.detailForm.id) {
      this.getCircleDetail()
    }
    this.getCategoryList()
  },
  components: {
    MediaWall,
    FixedActionBar,
    ContentList,
    LogRecodeList,
    CommentList,
    SelectMember,
    CheckPayment,
    AddCategoryButton,
  },
  beforeRouteEnter(to, from, next) {
    const type = to.params.type
    switch (type) {
      case 'add':
        to.meta.title = '资源发布'
        break
      case 'edit':
        to.meta.title = '资源详情'
        break
    }
    next()
  },
}
</script>

<style lang="scss" scoped>
.content {
  min-height: calc(100vh - 190px);
  min-width: 800px;

  .title {
    padding: 19px 4px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid #e9e9e9;
  }

  .confirm-content {
    padding: 24px 4px 20px 4px;

    .el-row {
      padding-bottom: 28px;
      font-size: 14px;

      ::v-deep.el-form-item__label {
        color: #000;
      }
    }

    ::v-deep.el-input-group__prepend {
      background-color: #fff;
    }

    .label {
      display: inline-block;
      text-align: right;
      line-height: 32px;
      width: 70px;
    }
  }

  .tool-tip {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
</style>
